<template>
  <v-container fluid>
    <v-card tile>

      <v-card-title>
        Reservation items
        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn @click="$router.push('/admin/reservation_items/new')" class="primary">New item</v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.descriptionText="{ item }">
          <small>{{ item.descriptionText }}</small>
        </template>

        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            @change="switchItemActiveState(item)"
            color="success"
          ></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            color="blue"
            @click="editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            color="red"
            @click="openDeleteItemDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="deleteItemDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteItemDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
export default {
  data () {
    return {
      path: 'reservation_items',
      items: [],
      item: null,
      deleteItemDialog: false,
      loading: true,
      search: '',
      headers: [
        { text: 'NAME', value: 'name', filterable: true },
        { text: 'ROOM NUMBER', value: 'roomNumber' },
        { text: 'E-MAIL', value: 'email' },
        { text: 'DESCRIPTION TEXT', value: 'descriptionText', width: '200px' },
        { text: 'MAX CONCURRENCY', value: 'maxConcurrency' },
        { text: 'MAX DURATION', value: 'maxDuration' },
        { text: 'AVAILABILITY START', value: 'availabilityStart' },
        { text: 'AVAILABILITY END', value: 'availabilityEnd' },
        { text: 'ACTIVE', value: 'active' },
        { text: 'ACTIONS', value: 'actions', sortable: false, width: '90px' }
      ]
    }
  },

  methods: {
    editItem (id) {
      this.$router.push(`/admin/${this.path}/edit?id=${id}`)
    },
    async switchItemActiveState (item) {
      await this.$store.dispatch('rest/updateItem', {
        path: this.path,
        item: item
      })
    },
    async deleteItem () {
      await this.$store.dispatch('rest/deleteItem', {
        path: this.path,
        id: this.item.id
      })
      this.items.splice(this.items.indexOf(this.item), 1)
      this.item = null
      this.deleteItemDialog = false
    },
    openDeleteItemDialog (item) {
      this.item = item
      this.deleteItemDialog = true
    }
  },
  async mounted () {
    document.title = 'Reservation items | Masarka Student Club'
    this.loading = true
    this.items = await this.$store.dispatch('rest/fetchAllItems', { path: this.path })
    this.loading = false
  }
}
</script>

<style scoped>

</style>
